<template>
  <v-dialog
    v-model="ifLoading"
    max-width="600px"
  >
    <v-card
      class="d-flex align-center justify-center flex-column"
      style="height: 400px"
    >
      <div class="mb-4 text-h3 font-weight-bold">
        稍等片刻
      </div>
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'LoadingDialog',
    props: ['justWait'],
    data: function () {
      return {
        ifLoading: false,
      }
    },
    watch: {
      justWait (val) {
        console.log(val, 'val')
        if (val) {
          this.ifLoading = true
        } else {
          this.ifLoading = false
        }
      },
    },
  }
</script>

<style scoped>

</style>
