<template>
  <v-container class="pa-6">
    <div class="d-flex">
      <div class="d-flex align-center py-4 pb-6">
        <span class="text-h2 font-weight-bold">{{ $t('tableUsefulSearch') }}</span>
      </div>
    </div>
    <div class="d-flex">
      <div>
        <v-card
          class="pa-4"
          elevation="0"
          outlined
          style="border-radius: 12px"
        >
          <div class="d-flex flex-column">
            <div class="text-subtitle-1 font-weight-bold">
              {{ $t('reservationNeedTable') }}
            </div>
          </div>
          <div class="mt-8">
            <div class="d-flex align-center">
              <div class="text-body-1">
                {{ $t('numberOfPeople') }}
              </div>
              <v-icon class="ml-2">
                mdi-human-male-female
              </v-icon>
            </div>
            <div class="text-caption mb-2">
              {{ $t('kidRule') }}
            </div>
            <div style="display: grid;grid-template-columns: repeat(4,minmax(0,1fr));grid-gap: 8px;width: 100%">
              <v-card
                v-for="n in 18"
                :key="n.id"
                class="pa-4"
                @click="searchUsefulTable(n)"
              >
                <div class="d-flex justify-center">
                  {{ $t('numberOfPerson', {personNumber: n}) }}
                </div>
              </v-card>
            </div>
            <v-divider class="my-2"/>
            <div class="d-flex justify-center">
              <v-btn
                class="success mr-2"
                @click="sureToAdd = true,isShowUsefulTable = false"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                {{ $t('新增') }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>
      <div class="ml-2">
        <v-card
          v-if="sureToAdd"
          class="pa-4"
          elevation="0"
          outlined
          style="border-radius: 12px"
        >
          <div class="font-weight-bold mb-1">
            {{ $t('newTableRules') }}
          </div>
          <div class="text-caption mb-2">
            {{ $t('chairRules') }}
          </div>
          <v-text-field
            v-model="adultCount"
            :suffix="$t('personCount')"
            outlined
            prepend-icon="mdi-human-male-female"
            style="width: 200px"
            type="number"
          />
          <v-text-field
            v-model="tableSeat"
            :suffix="$t('tableSeat')"
            outlined
            prepend-icon="mdi-table-furniture"
            style="width: 200px"
            type="number"
          />
          <v-text-field
            v-model="removeChair"
            :suffix="$t('chairUnit')"
            outlined
            prepend-icon="mdi-seat"
            style="width: 200px"
            type="number"
          />
          <div class="d-flex">
            <v-spacer/>
            <v-btn
              class="primary"
              @click="addTableRules"
            >
              {{ $t('确定') }}
            </v-btn>
          </div>
        </v-card>
      </div>
      <div class="ml-2">
        <v-card
          v-if="isShowUsefulTable"
          class="pa-4"
          elevation="0"
          outlined
          style="border-radius: 12px"
        >
          <div class="font-weight-bold">
            {{ $t('tableSeatSetting', {adultCount: adultCount}) }}
          </div>
          <template v-if="needTable.length >0">
            <div
              class="pa-4"
              style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 8px;width: 100%"
            >
              <div
                v-for="x in needTable"
                :key="x.id"
                class="pa-4"
              >
                <v-text-field
                  v-model="x.tableSeatCount"
                  :suffix="$t('tableSeat')"
                  outlined
                  prepend-icon="mdi-table-furniture"
                  style="width: 200px"
                  type="number"
                />
                <v-text-field
                  v-model="x.additionalChairCount"
                  :suffix="$t('chairUnit')"
                  outlined
                  prepend-icon="mdi-seat"
                  style="width: 200px"
                  type="number"
                />
                <div class="d-flex">
                  <v-btn
                    class="error"
                    @click="deleteTableRules(x)"
                  >
                    {{ $t('delete') }}
                  </v-btn>
                  <v-spacer/>
                  <v-btn
                    class="primary"
                    @click="editTableRules(x)"
                  >
                    {{ $t('save') }}
                  </v-btn>
                </div>
                <v-divider class="mt-4"/>
              </div>
            </div>
          </template>
          <template v-else>
            {{ $t('notFindDataPleaseNewRule') }}
          </template>
        </v-card>
      </div>
    </div>
    <loading-dialog :just-wait="justWait"/>
  </v-container>
</template>

<script>
  import {
    addTableReserveRules,
    deleteTableReserveRules,
    editTableReserveRules,
    getReserveSettings,
  } from '@/common/Utlis/api'
  import { showSuccessMessage } from '@/common/utils'
  import LoadingDialog from '@/composable/LoadingDialog'

  export default {
    name: 'TableRules',
    components: { LoadingDialog },
    data: () => {
      return ({
        isLoading: null,
        sureToAdd: false,
        tableSeat: null,
        removeChair: null,
        isShowUsefulTable: false,
        justWait: false,
        needTable: null,
        reserveSetting: null,
        adultCount: 1,
      })
    },
    mounted () {
      this.reloadData()
    },
    methods: {
      async deleteTableRules (item) {
        this.justWait = true
        await deleteTableReserveRules(item.id)
        this.justWait = false
        showSuccessMessage(this.$t('delete_success'))
        await this.reloadData()
        this.searchUsefulTable(this.adultCount)
      },
      async addTableRules () {
        this.justWait = true
        await addTableReserveRules({
          personCount: this.adultCount,
          tableSeatCount: this.tableSeat,
          additionalChairCount: -this.removeChair,
        })
        this.justWait = false
        showSuccessMessage(this.$t('successNew'))
        this.sureToAdd = false
        this.tableSeat = ''
        this.removeChair = ''
        await this.reloadData()
        this.searchUsefulTable(this.adultCount)
      },
      async editTableRules (item) {
        this.justWait = true
        await editTableReserveRules(item)
        this.justWait = false
        showSuccessMessage(this.$t('successSet'))
        await this.reloadData()
        this.searchUsefulTable(this.adultCount)
      },
      searchUsefulTable (n) {
        this.adultCount = n
        this.isShowUsefulTable = true
        this.sureToAdd = false
        this.needTable = this.reserveSetting.personTableRules.filter(it => parseInt(it.personCount) === n)
      },
      async reloadData () {
        this.reserveSetting = (await getReserveSettings())
      },
    },
  }
</script>

<style scoped>

</style>
